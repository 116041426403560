<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :visible.sync="dialog"
      title="添加限购次数"
      :close-on-click-modal="false"
      width="600px"
    >
      <!-- <div class="head-container">
        <el-input v-model="query.name" clearable placeholder="输入企业名称搜索" style="width: 200px;" class="filter-item" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="load">搜索</el-button>
      </div>
      <el-table :data="data" size="small" highlight-current-row @current-change="loadCurrentChangeOder" style="width: 100%;">
        <el-table-column prop="erpId" label="ERP编号" width="120" />
        <el-table-column prop="name" label="企业名称" show-overflow-tooltip />
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" /> -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="80px"
      >
        <el-form-item prop="disEntId" label="经销商">
          <!-- disErpCode: row.erpId, -->
          <quick-select
            class="filter-item"
            v-model="form.disEntId"
            :label.sync="form.disName"
            filterable
            value-field="enterpriseId"
            url="api/distributor"
            :filterMethod="
              (s, d) => {
                return s.filter((o) => {
                  return (
                    (o.erpId || '').toLowerCase().indexOf(d) >= 0 ||
                    (o.name || '').toLowerCase().indexOf(d) >= 0
                  );
                });
              }
            "
            placeholder="请选择经销商"
            @change="handleErpCode"
            style="width: 100%"
            clearable
          >
            <template slot-scope="scope"
              >（{{ scope.row.erpId }}）{{ scope.row.name }}</template
            >
          </quick-select>
        </el-form-item>
        <el-form-item label="限制次数" prop="buyTimes">
          <div class="h c">
            <el-input-number
              v-model="form.buyTimes"
              :min="0"
              :max="99999"
              style="width: 200px"
            />
            <div class="padding-0-05 fs-mini el-icon-warning fc-e">&nbsp;相同起止日期将累计本次设置次数</div>
          </div>
        </el-form-item>

        <el-form-item label="起止日期">
          <el-date-picker
            v-model="form.times"
            type="datetimerange"
            value-format="timestamp"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
            style="width: 100%"
          />
          <div class="padding-0-05 fs-mini el-icon-warning fc-e">&nbsp;未设置起止日期时,则表示长期有效</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "当前月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                1,
                0,0,0
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth()+1,
                0,
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      form: {
        buyTimes: null,
        beginAt: null,
        expireAt: null,
        disErpCode: null,
        disEntId:null,
      },
      rules: {
        disEntId: [
          { required: true, message: "请选择经销商", trigger: "blur" },
        ],
        buyTimes: [
          { required: true, message: "请选择限制次数", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleErpCode(n, o, obj) {
      this.form.disErpCode = obj.erpId;
    },
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.doAdd();
        }
      });
    },
    doAdd() {
      let data = JSON.parse(JSON.stringify(this.form));
      if (data.times != null && data.times.length > 1) {
        data.beginAt = data.times[0];
        data.expireAt = data.times[1];
      }
      request({
        url: "api/performanceBuyLimit",
        method: "post",
        data: data,
      })
        .then((_) => {
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.loadOder();
          this.dialog = false;
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    resetForm() {
      this.dialog = false;
      this.form = {
        disEntId:null,
        buyTimes: 1
      };
    },
  },
};
</script>

