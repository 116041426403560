var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"append-to-body":true,"visible":_vm.dialog,"title":"添加限购次数","close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"size":"small","label-width":"80px"}},[_c('el-form-item',{attrs:{"prop":"disEntId","label":"经销商"}},[_c('quick-select',{staticClass:"filter-item",staticStyle:{"width":"100%"},attrs:{"label":_vm.form.disName,"filterable":"","value-field":"enterpriseId","url":"api/distributor","filterMethod":function (s, d) {
              return s.filter(function (o) {
                return (
                  (o.erpId || '').toLowerCase().indexOf(d) >= 0 ||
                  (o.name || '').toLowerCase().indexOf(d) >= 0
                );
              });
            },"placeholder":"请选择经销商","clearable":""},on:{"update:label":function($event){return _vm.$set(_vm.form, "disName", $event)},"change":_vm.handleErpCode},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v("（"+_vm._s(scope.row.erpId)+"）"+_vm._s(scope.row.name))]}}]),model:{value:(_vm.form.disEntId),callback:function ($$v) {_vm.$set(_vm.form, "disEntId", $$v)},expression:"form.disEntId"}})],1),_c('el-form-item',{attrs:{"label":"限制次数","prop":"buyTimes"}},[_c('div',{staticClass:"h c"},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"min":0,"max":99999},model:{value:(_vm.form.buyTimes),callback:function ($$v) {_vm.$set(_vm.form, "buyTimes", $$v)},expression:"form.buyTimes"}}),_c('div',{staticClass:"padding-0-05 fs-mini el-icon-warning fc-e"},[_vm._v(" 相同起止日期将累计本次设置次数")])],1)]),_c('el-form-item',{attrs:{"label":"起止日期"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetimerange","value-format":"timestamp","start-placeholder":"开始日期","end-placeholder":"结束日期","default-time":['00:00:00', '23:59:59'],"picker-options":_vm.pickerOptions},model:{value:(_vm.form.times),callback:function ($$v) {_vm.$set(_vm.form, "times", $$v)},expression:"form.times"}}),_c('div',{staticClass:"padding-0-05 fs-mini el-icon-warning fc-e"},[_vm._v(" 未设置起止日期时,则表示长期有效")])],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.doSubmit}},[_vm._v("确认")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }